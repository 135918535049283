// Inherit the Styles from Material Themes
@use '@angular/material' as mat;

@use 'popsy.variables.scss';
@use 'popsy-light.colors.scss';

// Define the Popsy Default Font

/* $popsy-typography: mat.define-typography-config(
  $font-family: "'Nunito', sans-serif;",
  $headline-5: mat.define-typography-level(32px, 38px, 800),
  $headline-6: mat.define-typography-level(28px, 33px, 800),
  $subtitle-1: mat.define-typography-level(28px, 29px, 800),
  $body-1: mat.define-typography-level(21px, 25px, 700),
  $body-2: mat.define-typography-level(16px, 24px, 400),
  $subtitle-2: mat.define-typography-level(16px, 24px, 700),
  $caption: mat.define-typography-level(14px, 21px, 400),
); */

// Include the common styles for Angular Material.
// Be sure that you only ever include this mixin once!

/* @include mat.all-component-typographies($popsy-typography);
 */

/* @include mat.core();
 */

// Define the palettes for the theme

/* $popsy-app-primary: mat.define-palette(popsy-light.$popsy-colors-primary);
$popsy-app-accent: mat.define-palette(popsy-light.$popsy-colors-secondary); */

// Create the theme object (a Sass map containing all of the palettes).

/* $popsy-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $popsy-app-primary,
      accent: $popsy-app-accent,
    ),
  )
); */

// Include theme styles for core and each component used in the app.

/* @include mat.all-component-themes($popsy-app-theme);
 */
