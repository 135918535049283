@use 'sass:color';
@use 'sass:map';
@use 'sass:meta';
@use 'popsy-light.colors';
@use 'popsy.variables';

// Variables needed by Mixins
@use 'sass:math';
@use 'sass:selector';

$popsy-input-height: 40px;

@function popsy-contrast-palette($palette, $hue) {
  @return map.get(map.get($palette, contrast), $hue);
}

@function popsy-color-palette($palette, $hue: default, $opacity: null) {
  // If hueKey is a number between zero and one, then it actually contains an
  // opacity value, so recall this function with the default hue and that given opacity.
  @if meta.type-of($hue) == number and $hue >= 0 and $hue <= 1 {
    @return popsy-color-palette($palette, default, $hue);
  }

  $color: map.get($palette, $hue);

  @if meta.type-of($color) != color {
    // If the $color resolved to something different from a color (e.g. a CSS variable),
    // we can't apply the opacity anyway so we return the value as is, otherwise Sass can
    // throw an error or output something invalid.
    @return $color;
  }

  @return rgba($color, if($opacity == null, color.opacity($color), $opacity));
}

@mixin popsy-box() {
  display: block;
  position: relative;
  box-sizing: border-box;

  // Fix Safari Mobile Scroll Issues
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transform-origin: 50% 51%;
}

@mixin popsy-block() {
  @include popsy-box();
  padding: 0 popsy.$block-padding;
  max-width: popsy.$block-max-width;
  margin: 0 auto;
}

@mixin popsy-text-caption() {
  font-size: 14px;
  line-height: 21px;
  min-height: 15px;
  font-display: swap;
}

@mixin popsy-text-body-2() {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  min-height: 16px;
  font-display: swap;
  margin: 8px 0;
}

@mixin popsy-text-body-1() {
  font-size: 16px;
  line-height: 24px;
  min-height: 16px;
  font-display: swap;
}

@mixin popsy-text-subheading-1() {
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  min-height: 20px;
  font-display: swap;
  margin: 8px 0;
}

@mixin popsy-text-subheading-2() {
  font-size: 25px;
  line-height: 30px;
  font-weight: 700;
  min-height: 30px;
  font-display: swap;
  margin: 12px 0;
}

@mixin popsy-text-title() {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  min-height: 30px;
  font-display: swap;
  margin: 12px 0;
}

@mixin popsy-text-headline() {
  font-size: 50px;
  line-height: 55px;
  font-weight: 600;
  min-height: 50px;
  font-display: swap;
  margin: 16px 0;
}

@mixin mat-icon-size($size: 24px) {
  font-size: $size;
  height: $size;
  min-height: $size;
  max-height: $size;
  width: $size;
  min-width: $size;
  max-width: $size;
  line-height: $size;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: '';
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin popsy-button() {
  @include popsy-box();
  @include popsy-text-caption();
  display: flex;
  align-items: center;
  justify-content: center;
  height: $popsy-input-height;
  min-width: 120px;
  border-radius: 6px;
  text-decoration: none;
  outline: none;
  padding: 8px 16px;

  .mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $popsy-input-height;
  }
}

@mixin popsy-scroll {
  /* Works on Firefox */
  scrollbar-width: thin;
  scrollbar-color: popsy-color-palette(popsy-light.$popsy-colors-gray, 300)
    popsy-color-palette(popsy-light.$popsy-colors-gray, 200);

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: popsy-color-palette(popsy-light.$popsy-colors-gray, 200);
  }

  &::-webkit-scrollbar-thumb {
    background-color: popsy-color-palette(popsy-light.$popsy-colors-gray, 300);
    border-radius: 20px;
    border: 1px solid popsy-color-palette(popsy-light.$popsy-colors-gray, 200);
  }
}

@mixin popsy-ui-skeleton() {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgb(239, 241, 246);
  z-index: 44;
  overflow: hidden;
  border-radius: 5px;

  .activity {
    position: absolute;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(
      to left,
      rgba(251, 251, 251, 0.05),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.6),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.05)
    );
    animation: loading 1s infinite;
    z-index: 45;
  }
}

@mixin popsy-select-field() {
  @include popsy-text-caption();
  @include popsy-box();
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 40px;
  max-height: 40px;
  text-align: start;
  padding: 10px 12px;
  appearance: unset;
  color: popsy-color-palette(popsy-light.$popsy-colors-gray, 600);
  caret-color: popsy-color-palette(popsy-light.$popsy-colors-secondary, 500);
  background-color: popsy-color-palette(popsy-light.$popsy-colors-gray, 50);
  border: 1px solid popsy-color-palette(popsy-light.$popsy-colors-gray, 200);
  border-radius: 6px;
  resize: none;

  &.initialized {
    &::placeholder {
      color: popsy-color-palette(popsy-light.$popsy-colors-gray, 600);
    }
  }

  &:focus::placeholder {
    transition: opacity 0.5s 0.5s ease;
    opacity: 1;

    &.initialized {
      &::placeholder {
        color: popsy-color-palette(popsy-light.$popsy-colors-gray, 300);
      }
    }
  }

  &.field-button {
    cursor: pointer;

    &:hover {
      background-color: popsy-color-palette(popsy-light.$popsy-colors-gray, 100);
    }
  }

  &::placeholder {
    @include popsy-text-caption();
    color: popsy-color-palette(popsy-light.$popsy-colors-gray, 300);
    line-height: -moz-block-height;
  }

  &.placeholder {
    color: popsy-color-palette(popsy-light.$popsy-colors-gray, 300) !important;
  }

  &:focus {
    outline: none;
    border-color: inherit;
    box-shadow: none;
    // border-color: #0598e2;

    border: 1px solid popsy-color-palette(popsy-light.$popsy-colors-secondary, 50);
    background-color: popsy-color-palette(popsy-light.$popsy-colors-gray, 50);
  }

  &[disabled] {
    background-color: rgba(popsy-color-palette(popsy-light.$popsy-colors-gray, 200), 0.4);
    color: rgba(popsy-color-palette(popsy-light.$popsy-colors-gray, 400), 0.4);

    &::placeholder {
      color: rgba(popsy-color-palette(popsy-light.$popsy-colors-gray, 400), 0.4);
      border: 0 !important;
    }

    &.field-button {
      background-color: popsy-color-palette(popsy-light.$popsy-colors-gray, 100);
    }
  }
}

@keyframes loading {
  0% {
    transform: translateX(-45%);
  }

  100% {
    transform: translateX(345%);
  }
}

// mixing for defining child styles on parent class property
@mixin on-top-parent($parent) {
  @at-root #{selector.append( $parent, &)} {
    @content;
  }
}
