@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/poppins/Poppins-Regular.ttf');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/poppins/Poppins-Bold.ttf');
}

body.ltr.virgin * {
  font-family: Poppins, CurrencySymbol !important;
}

body.virgin {
  app-root,
  app-virgin-toolbar {
    background: linear-gradient(
      to bottom,
      #ffd8d8 0,
      #ffd8d8 17px,
      #ece9e9 150px,
      #f8f8f8 100%
    );
  }

  app-home-view {
    .link {
      background-color: #fff !important;

      &.selected {
        background-color: #e10a0a !important;
      }
    }
  }

  .popsy-button-primary.blue-button,
  .popsy-button-primary.highlight,
  app-cart-promo-overlay .options-dialog .header,
  app-checkout-order-summary .free,
  app-insurance-card .red {
    background-color: #e10a0a !important;
  }

  app-checkout-order-summary-item .badge,
  app-cart-list-item .badge {
    border-color: #e10a0a !important;
    background-color: #e10a0a !important;
  }

  .icon.fill-gray-dark {
    > svg {
      path {
        stroke: none !important;
        fill: #e10a0a !important;
      }
    }
  }

  /* popsy-icon-cart-logo,
  popsy-icon-avatar {
    > svg {
      path {
        fill: none !important;
        stroke: #e10a0a !important;
      }
    }
  } */

  .popsy-sort-by-filter,
  .grid-item.brand-item,
  .featured-discount-badge {
    background-color: #e10a0a !important;
  }

  .featured-filters-toolbar-component {
    background-color: unset !important;
  }

  app-featured-discount-badge {
    top: 10px !important;
  }

  app-featured-listing-card .swiper-wrapper {
    height: 90% !important;
  }

  app-featured-listing-card popsy-gallery {
    padding: 16px;
    border-radius: 20px;
    background-color: #fff;
  }

  .text-secondary,
  .text-highlight {
    color: #1b3b4b !important;
  }
}
