/* nunito-regular - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/nunito-v16-latin/nunito-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/nunito-v16-latin/nunito-v16-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/assets/fonts/nunito-v16-latin/nunito-v16-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/nunito-v16-latin/nunito-v16-latin-regular.woff') format('woff'),
    /* Modern Browsers */
      url('/assets/fonts/nunito-v16-latin/nunito-v16-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/nunito-v16-latin/nunito-v16-latin-regular.svg#Nunito')
      format('svg'); /* Legacy iOS */
  unicode-range: U+0020-007F;
}

/* nunito-700 - latin */
@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/nunito-v16-latin/nunito-v16-latin-700.eot'); /* IE9 Compat Modes */
  src:
    local(''),
    url('/assets/fonts/nunito-v16-latin/nunito-v16-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('/assets/fonts/nunito-v16-latin/nunito-v16-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('/assets/fonts/nunito-v16-latin/nunito-v16-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('/assets/fonts/nunito-v16-latin/nunito-v16-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('/assets/fonts/nunito-v16-latin/nunito-v16-latin-700.svg#Nunito') format('svg'); /* Legacy iOS */

  unicode-range: U+0020-007F;
}
